<template>
  <base-modal
    v-model="model"
    :close-on-click-outside="true"
    :title="alertTitle"
    :can-overflow="true"
  >
    <base-alert
      title="Navnet må ikke være det samme som et eksisterende workspace/eksperiment."
      class="mt-4"
      type="info"
      :dismissable="false"
    ></base-alert>

    <base-input
      v-model="form.name"
      type="text"
      class="mt-4"
      label="Nyt navn"
      :required="false"
      tabindex="1"
    ></base-input>

    <div slot="footer" class="flex justify-end">
      <base-button tabindex="2" color="indigo" :disabled="$v.form.$invalid" @click="submit">Omdøb</base-button>
    </div>
  </base-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import vuelidateMixin from '@/mixins/vuelidateMixin';

export default {
  name: 'WorkspaceRenameModal',
  mixins: [vuelidateMixin],
  props: {
    value: {
      type: Boolean,
    },
    currentName: {
      type: String,
      required: true,
    },
    isInTransitionPeriod: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: '',
      },
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
    alertTitle() {
  return this.isInTransitionPeriod ? 'Omdøb mellemtømninger' : 'Omdøb workspace/eksperiment';
},
  },
  watch: {
    currentName: {
      handler: 'setCurrentName',
      immediate: true,
    },
  },
  methods: {
    async submit() {
      this.$emit('rename', this.form.name);
    },
    setCurrentName() {
      this.form.name = this.currentName;
    },
  },
  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },
};
</script>
