<template>
  <base-modal
    v-model="model"
    :close-on-click-outside="true"
    title="Tilføj eksperiment"
    :can-overflow="true"
  >
    <base-input v-model="form.name" type="text" label="Navn" :required="true" tabindex="1"></base-input>

    <div slot="footer" class="flex justify-end">
      <base-button tabindex="3" color="indigo" :disabled="$v.form.$invalid" @click="submit">Opret</base-button>
    </div>
  </base-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import vuelidateMixin from '@/mixins/vuelidateMixin';

export default {
  name: 'WorkspaceExperimentCreateModal',
  mixins: [vuelidateMixin],
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        name: '',
      },
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
  },
  methods: {
    async submit() {
      this.$emit('create', this.form);
    },
  },
  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },
};
</script>
