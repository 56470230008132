<template>
  <base-modal
      v-model="model"
      title="Del dit workspace med andre brugere"
      :can-overflow="true"
  >
    <div class="mt-4">
      <h3 class="flex items-center font-medium text-gray-900">
        <span>Valgt workspace:</span>
        <span class="ml-2 font-normal text-gray-600">{{ selectedWorkspace }}</span>
      </h3>
    </div>

    <div class="mt-4">
      <h3 class="mb-2">Klik på en eller flere brugere for at slå deling til eller fra:</h3>
      <p v-if="filteredUsers.length === 0">Ingen brugere er tilgængelige</p>
      <div v-else class="max-h-60 overflow-y-auto">
        <div
            v-for="user in filteredUsers"
            :key="user.id"
            class="mb-2 flex items-center cursor-pointer"
            @click="toggleUserSelection(user.id)"
        >
          <base-checkbox
              :value="isUserSelected(user.id)"
              @input="toggleUserSelection(user.id)"
          />
          <span class="ml-2">
            {{ user.full_name }} ({{ user.email }})
          </span>
        </div>
      </div>
    </div>

    <div slot="footer" class="flex justify-end">
      <base-button
          color="indigo"
          :disabled="!hasChanges"
          @click="submit"
      >Gem ændringer</base-button>
    </div>
  </base-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FETCH_USERS, FETCH_SHARED_USERS } from '@/store/actions.type';

export default {
  name: 'WorkspaceShareDepartmentModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    workspaceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedUserIdsObj: {},
      initialSelectedUserIds: new Set(),
    };
  },
  computed: {
    ...mapGetters('departments', ['workspaces']),
    ...mapGetters('users', ['users', 'sharedUsers']),
    ...mapGetters('auth', ['currentUser']),
    model: {
      get() { return this.value; },
      set(value) {
        this.$emit('input', value);
        if (!value) {
          this.selectedUserIdsObj = {};
          this.initialSelectedUserIds = new Set();
        }
      },
    },
    filteredUsers() {
      return this.users.filter((user) => user.id !== this.currentUser.id);
    },
    selectedWorkspace() {
      const workspace = this.workspaces.find((w) => w.id === this.workspaceId);
      return workspace.name;
    },
    selectedUserIds() {
      return Object.keys(this.selectedUserIdsObj).filter((id) => this.selectedUserIdsObj[id]);
    },
    hasChanges() {
      const currentSelection = new Set(this.selectedUserIds);
      return currentSelection.size !== this.initialSelectedUserIds.size
          || ![...currentSelection].every((id) => this.initialSelectedUserIds.has(id));
    },
  },
  watch: {
    model(newValue) {
      if (newValue) {
        this.loadInitialState();
      }
    },
  },
  methods: {
    ...mapActions('departments', ['shareDepartment']),
    ...mapActions('users', { fetchUsers: FETCH_USERS, fetchSharedUsers: FETCH_SHARED_USERS }),
    async loadInitialState() {
      await Promise.all([
        this.fetchUsers(),
        this.fetchSharedUsers(this.workspaceId),
      ]);

      this.selectedUserIdsObj = {};
      this.initialSelectedUserIds = new Set();

      this.sharedUsers.forEach((user) => {
        if (user.id !== this.currentUser.id) {
          this.$set(this.selectedUserIdsObj, user.id, true);
          this.initialSelectedUserIds.add(user.id);
        }
      });
    },
    isUserSelected(userId) {
      return !!this.selectedUserIdsObj[userId];
    },
    toggleUserSelection(userId) {
      this.$set(this.selectedUserIdsObj, userId, !this.selectedUserIdsObj[userId]);
    },
    async submit() {
      if (!this.hasChanges) return;

      await this.shareDepartment({
        departmentId: this.workspaceId,
        userIds: [...this.selectedUserIds, this.currentUser.id],
      });

      this.initialSelectedUserIds = new Set(this.selectedUserIds);
      this.$emit('shared', [...this.selectedUserIds, this.currentUser.id]);
      this.model = false;
    },
  },
};
</script>
