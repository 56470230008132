<template>
  <div class="flex items-center p-2 border-b">
    <div class="flex items-center space-x-1">
      <!-- Filter Type Selector -->
      <select
          v-model="localFilterType"
          class="w-40 pl-2 pr-8 py-2 border rounded-md text-sm text-gray-600 focus:ring-2 focus:ring-indigo-500"
          @change="emitFilterChange"
      >
        <option value="created_at">Workspace oprettet</option>
        <option value="last_visited">Workspace sidst besøgt</option>
        <option value="group_created_at">Gruppe oprettet</option>
      </select>

      <!-- Date Range Inputs -->
      <div class="flex items-center space-x-1">
        <input
            v-model="localStartDate"
            type="date"
            class="w-36 px-3 py-2 border rounded-md text-sm"
            :max="localEndDate || undefined"
            @change="emitFilterChange"
        />
        <input
            v-model="localEndDate"
            type="date"
            class="w-36 px-3 py-2 border rounded-md text-sm"
            :min="localStartDate || undefined"
            @change="emitFilterChange"
        />

      </div>
      <!-- Active Filter Indicator -->
      <div v-if="hasActiveFilters" class="flex items-center ml-2">
        <base-button
            class="text-sm text-gray-500 whitespace-nowrap min-w-[80px]"
            @click="clearFilters"
        >
          Ryd filter
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorkspaceDateFilter',

  props: {
    filterType: {
      type: String,
      default: 'created_at',
    },
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      localFilterType: this.filterType,
      localStartDate: this.startDate,
      localEndDate: this.endDate,
    };
  },

  computed: {
    hasActiveFilters() {
      return this.localStartDate || this.localEndDate;
    },
  },

  methods: {
    formatDate(dateStr) {
      return new Intl.DateTimeFormat('da-DK', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }).format(new Date(dateStr));
    },

    emitFilterChange() {
      this.$emit('filter-change', {
        filterType: this.localFilterType,
        startDate: this.localStartDate,
        endDate: this.localEndDate,
      });
    },

    clearFilters() {
      this.localStartDate = null;
      this.localEndDate = null;
      this.emitFilterChange();
    },
  },
};
</script>
