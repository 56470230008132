<template>
  <div :class="{'pl-4': depth > 0}">
    <span :class="{'font-bold': item.type === 'group'}">{{ item.name }}</span>
    <template v-if="item.children && item.children.length > 0">
      <group-hierarchy-view
        v-for="child in item.children"
        :key="child.name"
        :item="child"
        :depth="depth + 1"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'GroupHierarchyView',
  props: {
    item: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      default: 0,
    },
  },
};
</script>
