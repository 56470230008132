<template>
  <base-modal
      v-model="model"
      :close-on-click-outside="true"
      title="Ryk til anden gruppe"
      :can-overflow="true"
  >
    <base-input
        v-if="workspace"
        v-model="workspace.name"
        type="text"
        class="mt-4"
        label="Workspace"
        :required="false"
        :disabled="true"
    ></base-input>

    <base-alert
        title="Hvis en eksisterende gruppe er valgt, så benyttes denne"
        type="info"
        class="mt-4"
    ></base-alert>

    <base-input label="Vælg eksisterende gruppe" class="mt-4">
      <base-select
          slot="input"
          v-model="form.groupId"
          width="w-full"
          :clearable="true"
          :options="groupOptions"
          tabindex="1"
          placeholder="Vælg eksisterende gruppe"
          @input="onExistingGroupSelect"
      ></base-select>
    </base-input>

    <base-input
        v-model="form.groupName"
        type="text"
        class="mt-4"
        label="Opret en ny gruppe"
        :required="false"
        tabindex="2"
        :disabled="!!form.groupId"
        @input="onNewGroupInput"
    ></base-input>

    <p v-if="errorMessage" class="text-red-500 mt-2">{{ errorMessage }}</p>

    <div slot="footer" class="flex justify-end">
      <base-button
          tabindex="3"
          color="indigo"
          :disabled="!isFormValid"
          @click="submit"
      >Ryk</base-button>
    </div>
  </base-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WorkspaceAssignGroupModalModal',
  props: {
    value: {
      type: Boolean,
    },
    workspace: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        id: '',
        groupName: '',
        groupId: '',
      },
      errorMessage: '',
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
    ...mapGetters('departments', ['groupOptions']),
    isFormValid() {
      return (this.form.groupId || this.form.groupName) && !this.errorMessage;
    },
  },
  watch: {
    workspace: {
      handler: 'setForm',
      immediate: true,
    },
    form: {
      handler: 'validateForm',
      deep: true,
    },
  },
  methods: {
    async submit() {
      if (!this.isFormValid) return;

      const formattedForm = {
        ...this.form,
        groupId: this.form.groupId || null,
      };

      this.$emit('assigned', formattedForm);
    },
    setForm() {
      this.form.id = this.workspace?.id || '';
      this.form.groupName = '';
      this.form.groupId = this.workspace?.groupId || '';
      this.validateForm();
    },
    onExistingGroupSelect(value) {
      if (value) {
        this.form.groupName = '';
      }
      this.validateForm();
    },
    onNewGroupInput(value) {
      if (value) {
        this.form.groupId = '';
      }
      this.validateForm();
    },
    validateForm() {
      if (this.form.groupId && this.form.groupName) {
        this.errorMessage = 'Du kan ikke både vælge en eksisterende gruppe og oprette en ny gruppe.';
      } else {
        this.errorMessage = '';
      }
    },
  },
};
</script>
