<template>
  <li class="relative bg-white my-2 group overflow-hidden">
    <div class="flex justify-between w-full py-6 px-6 cursor-pointer" @click="toggleOpen">
      <div class="flex items-center">
        <icon-folder v-if="isTopLevelGroup" class="h-6 w-6 mr-2 text-indigo-600"></icon-folder>
        <icon-sub-folder v-else class="h-6 w-6 mr-2 text-indigo-600"></icon-sub-folder>
        <div class="flex flex-col">
            <span :class="{'font-semibold': isTopLevelGroup, 'font-normal': !isTopLevelGroup}"
                  v-html="$options.filters.highlight(group.name, searchFilter)">
            </span>
          <span class="text-sm font-extralight text-gray-500">Oprettet: {{ formatDate(group.created_at) }}</span>
        </div>
      </div>
      <div class="ml-6 flex items-center">
        <span v-if="isEmptyGroup" class="text-sm text-gray-500">(Tom gruppe)</span>
        <template v-else>
          <icon-plus v-if="!isOpen" class="h-5 w-5"></icon-plus>
          <icon-minus v-else class="h-5 w-5"></icon-minus>
        </template>
      </div>
    </div>

    <collapse-transition>
      <div v-if="isOpen" class="pl-6">
        <!-- Render workspaces within this group -->
        <workspace-list-item
            v-for="ws in group.workspaces"
            :key="ws.id"
            :workspace="ws"
            :search-filter="searchFilter"
            @setWorkspace="$emit('setWorkspace', $event)"
            @deleteWorkspace="$emit('deleteWorkspace', $event)"
            @openAssignWorkspace="$emit('openAssignWorkspace', $event)"
            @updateLastVisited="$emit('updateLastVisited', $event)"
        ></workspace-list-item>

        <!-- Recursively render nested groups -->
        <workspace-list-group-item
            v-for="nestedGroup in group.groups"
            :key="nestedGroup.id"
            :group="nestedGroup"
            :search-filter="searchFilter"
            :date-filter="dateFilter"
            :is-subfolder="true"
            @setWorkspace="$emit('setWorkspace', $event)"
            @deleteWorkspace="$emit('deleteWorkspace', $event)"
            @openAssignWorkspace="$emit('openAssignWorkspace', $event)"
            @updateLastVisited="$emit('updateLastVisited', $event)"
        ></workspace-list-group-item>
      </div>
    </collapse-transition>
  </li>
</template>

<script>
import { CollapseTransition } from 'vue2-transitions';
import WorkspaceListItem from './WorkspaceListItem.vue';

export default {
  name: 'WorkspaceListGroupItem',
  components: {
    WorkspaceListItem,
    CollapseTransition,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    searchFilter: {
      type: String,
      default: '',
    },
    isSubfolder: {
      type: Boolean,
      default: false,
    },
    dateFilter: {
      type: Object,
      default: () => ({
        filterType: 'created_at',
        startDate: null,
        endDate: null,
      }),
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    isEmptyGroup() {
      const isDateInRange = (date) => {
        if (!this.dateFilter.startDate && !this.dateFilter.endDate) return true;
        if (!date) return false;

        const checkDate = new Date(date);
        const startDate = this.dateFilter.startDate ? new Date(this.dateFilter.startDate) : null;
        const endDate = this.dateFilter.endDate ? new Date(this.dateFilter.endDate) : null;

        // eslint-disable-next-line no-unused-expressions
        startDate?.setHours(0, 0, 0, 0);
        // eslint-disable-next-line no-unused-expressions
        endDate?.setHours(23, 59, 59, 999);

        if (startDate && endDate) return checkDate >= startDate && checkDate <= endDate;
        if (startDate) return checkDate >= startDate;
        if (endDate) return checkDate <= endDate;
        return true;
      };

      const groupMatchesDateFilter = this.dateFilter.filterType === 'created_at'
          ? isDateInRange(this.group.created_at) : true;

      return (!groupMatchesDateFilter || !this.group.workspaces || this.group.workspaces.length === 0)
          && (!this.group.groups || this.group.groups.length === 0);
    },
    isTopLevelGroup() {
      return !this.isSubfolder;
    },
    hasActiveDateFilter() {
      return this.dateFilter.startDate || this.dateFilter.endDate;
    },
  },
  watch: {
    searchFilter: {
      immediate: true,
      handler(newSearch) {
        if (newSearch) {
          this.isOpen = true;
        } else if (!this.hasActiveDateFilter) {
          this.isOpen = false;
        }
      },
    },
    dateFilter: {
      deep: true,
      immediate: true,
      handler(newDateFilter) {
        if (newDateFilter.startDate || newDateFilter.endDate) {
          this.isOpen = true;
        } else if (!this.searchFilter) {
          this.isOpen = false;
        }
      },
    },
  },
  methods: {
    toggleOpen() {
      if (!this.isEmptyGroup) {
        this.isOpen = !this.isOpen;
      }
    },
    formatDate(date) {
      if (!date) return '';
      return new Intl.DateTimeFormat('da-DK', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(date));
    },
  },
};
</script>
