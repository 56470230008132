<template>
  <base-modal
    v-model="model"
    :close-on-click-outside="true"
    title="Opret workspace"
    :can-overflow="true"
  >
    <base-input v-model="form.name" type="text" label="Navn" :required="true" tabindex="1"></base-input>

    <input-dawa
      v-model="form.address"
      class="mt-4"
      autocomplete="none"
      tabindex="2"
      :invalid="invalid($v.form.address)"
      :valid="valid($v.form.address)"
      :required="true"
      label="Renovationspladsens adresse"
      tooltip="Dette har kun relevans for algoritmen"
    ></input-dawa>

    <base-input label="Vælg eksisterende gruppe" class="mt-4">
      <base-select
        slot="input"
        v-model="form.groupId"
        width="w-full"
        :clearable="true"
        :options="groupOptions"
        tabindex="3"
        placeholder="Vælg eksisterende gruppe"
        @input="onFormGroupInput"
      ></base-select>
    </base-input>

    <base-input
      v-model="form.groupName"
      :disabled="form.groupId != ''"
      type="text"
      class="mt-4"
      label="Opret en ny gruppe"
      :required="false"
      tabindex="4"
    ></base-input>

    <base-input class="mt-4">
      <base-checkbox
        slot="input"
        v-model="form.isLightWorkspace"
        tabindex="5"
        description="Skal workspacet benyttes til at planlægge ekstrakørsler?"
        label="Forbeholdt ekstrakørsler"
      ></base-checkbox>
    </base-input>

    <base-alert
      v-if="form.isLightWorkspace"
      title="Ekstrakørsler"
      description="Hvis workspacet oprettes til ekstrakørsler, vil funktioner som mellemtømninger, kommuneeksport mm. ikke være tilgængelige"
      type="info"
      class="mt-4"
    ></base-alert>

    <div slot="footer" class="flex justify-end">
      <base-button tabindex="6" color="indigo" :disabled="$v.form.$invalid" @click="submit">Opret</base-button>
    </div>
  </base-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import vuelidateMixin from '@/mixins/vuelidateMixin';
import InputDawa from '@/components/form/InputDawa.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'WorkspaceCreateModal',
  components: {
    InputDawa,
  },
  mixins: [vuelidateMixin],
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        name: '',
        groupName: '',
        groupId: '',
        address: '',
        isLightWorkspace: false,
      },
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
    ...mapGetters('departments', ['groupOptions']),
  },
  methods: {
    async submit() {
      const formattedForm = {
        ...this.form,
      };

      if (!this.form.groupId) {
        formattedForm.groupId = null;
      }

      this.$emit('create', formattedForm);
    },
    onFormGroupInput() {
      this.form.groupName = '';
    },
  },
  validations() {
    return {
      form: {
        name: { required },
        address: { required },
      },
    };
  },
};
</script>
