<template>
  <li class="relative pl-4 pr-6 py-5 hover:bg-gray-50 group sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6 mb-5 mr-5">
    <div class="flex items-center justify-between space-x-4">
      <!-- Repo name and link -->
      <div class="min-w-0 space-y-3">
        <div class="flex items-center space-x-3">
  <span
      class="h-4 w-4 bg-gray-100 group-hover:bg-green-100 rounded-full flex items-center justify-center"
      aria-hidden="true"
  >
    <span class="h-2 w-2 bg-gray-400 group-hover:bg-green-400 rounded-full"></span>
  </span>
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="$options.filters.highlight(workspace.name, searchFilter)"></span>
          <!-- eslint-enable -->
        </div>
        <div class="text-sm text-gray-500 ml-7">
          Sidst besøgt: {{ formatLastVisited}}
        </div>
          <div v-if="workspace.experiments && workspace.experiments.length > 0" class="relative group flex items-center ml-7">
            <span class="text-sm text-gray-500">Eksperimenter: </span>
            <div class="flex items-center ml-2">
              <template v-for="(experiment, idx) in workspace.experiments">
                <button
                    :key="experiment.id"
                    class="text-sm text-gray-500 hover:text-indigo-700 cursor-pointer"
                    @click="onWorkspaceListItemClick(experiment, workspace)"
                >
                  {{ experiment.name }}
                </button>
                <span
                    v-if="idx !== workspace.experiments.length - 1"
                    :key="`separator-${experiment.id}`"
                    class="text-sm text-gray-500 mx-2"
                > - </span>
              </template>
            </div>
          </div>
      </div>

      <!-- Repo meta info -->
      <div class="flex flex-col flex-shrink-0 items-end space-y-2">
        <button
            class="flex items-center space-x-4 relative"
            @click="onSetActiveWorkspace(workspace.id)"
        >
          <span
              class="text-sm text-indigo-700 hover:text-indigo-900 font-medium"
          >Sæt som aktivt workspace</span>
        </button>
        <button
            class="flex items-center space-x-4 relative"
            @click="$emit('openAssignWorkspace', workspace)"
        >
          <span class="text-sm text-gray-700 hover:text-gray-900 font-medium">Ryk workspace</span>
        </button>
        <button
            class="flex items-center space-x-4 relative"
            @click="showShareDepartmentModal = true"
        >
          <span class="text-sm text-gray-700 hover:text-gray-900 font-medium">Del workspace</span>
        </button>
        <button
            class="flex text-gray-500 text-sm space-x-2 cursor-pointer hover:text-red-700"
            @click.stop="deleteWorkspaceConfirmBox(workspace)"
        >Slet</button>
      </div>
    </div>
    <workspace-share-department-modal
        v-model="showShareDepartmentModal"
        :workspace-id="workspace.id"
        @shared="onWorkspaceShared"
    ></workspace-share-department-modal>
  </li>
</template>

<script>
import WorkspaceShareDepartmentModal from '@/components/WorkspaceShareDepartmentModal';

export default {
  name: 'WorkspaceListItem',
  components: {
    WorkspaceShareDepartmentModal,
  },
  props: {
    workspace: {
      type: Object,
      default: () => [],
    },
    searchFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showShareDepartmentModal: false,
    };
  },
  computed: {
    formatLastVisited() {
      if (!this.workspace.last_visited) return 'Endnu ikke besøgt';

      return new Intl.DateTimeFormat('da-DK', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(this.workspace.last_visited));
    },
  },
  methods: {
    async deleteWorkspaceConfirmBox({ id, name }) {
      try {
        await this.$confirm({
          title: `Slet workspace herunder alle eksperimenter (inkl. Main) og mellemtømninger for ${name}`,
          bodyText: 'Dette kan ikke laves om eller gendannes og er sidste advarsel',
        });
        this.$emit('deleteWorkspace', id);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    onWorkspaceListItemClick(experiment, workspace) {
      const id = experiment.id ?? workspace.id;
      this.$emit('setWorkspace', id);
    },
    onWorkspaceShared({ workspaceId, userIds }) {
      this.$emit('workspaceShared', { workspaceId, userIds });
      this.$ntf.success('Valgte workspace blev delt');
    },
    async onSetActiveWorkspace(workspaceId) {
      this.$emit('updateLastVisited', workspaceId);
      this.$emit('setWorkspace', workspaceId);
    },
  },
};
</script>

<style>
</style>
