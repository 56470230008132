<template>
  <base-modal
      v-model="model"
      :close-on-click-outside="true"
      title="Slet gruppe"
      :can-overflow="true"
  >
    <base-input label="Vælg gruppe at slette" class="mt-4">
      <base-select
          slot="input"
          v-model="selectedGroupId"
          width="w-full"
          :clearable="false"
          :options="groupOptions"
          tabindex="1"
          placeholder="Vælg en gruppe at slette"
      ></base-select>
    </base-input>

    <base-alert
        v-if="selectedGroup"
        :title="alertTitle"
        :type="canDelete ? 'warning' : 'error'"
        class="mt-4"
    >
      <div v-if="canDelete">
        <p>Følgende gruppe struktur vil blive slettet:</p>
        <hierarchy-view :item="groupHierarchy" />
      </div>
      <div v-else>
        <p>Gruppen kan ikke slettes, da den eller dens undergrupper indeholder workspaces.</p>
        <p>Slet venligst følgende workspaces først:</p>
        <ul class="list-disc pl-5 mt-2">
          <li v-for="workspace in containedWorkspaces" :key="workspace.id">
            {{ workspace.name }} (i gruppe: {{ workspace.groupName }})
          </li>
        </ul>
        <p v-if="containedWorkspaces.length === 0" class="text-red-500">
          Ingen workspaces fundet. Dette er sandsynligvis en fejl.
        </p>
      </div>
    </base-alert>

    <div slot="footer" class="flex justify-end">
      <base-button
          tabindex="2"
          color="red"
          :disabled="!canDelete || !selectedGroupId"
          @click="confirmDelete"
      >Slet gruppe</base-button>
    </div>
  </base-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import HierarchyView from '@/components/GroupHierachyView.vue';

export default {
  name: 'WorkspaceDeleteGroupModal',
  components: {
    HierarchyView,
  },
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedGroupId: '',
    };
  },
  computed: {
    ...mapGetters('departments', ['groupOptions', 'getGroupById']),
    model: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); },
    },
    selectedGroup() {
      // eslint-disable-next-line no-mixed-operators
      return this.selectedGroupId && this.getGroupById(this.selectedGroupId) || null;
    },
    groupHierarchy() {
      return this.selectedGroup ? this.buildHierarchy(this.selectedGroup) : null;
    },
    containedWorkspaces() {
      const workspaces = this.getContainedWorkspaces(this.selectedGroup);
      return workspaces;
    },
    canDelete() {
      return this.containedWorkspaces.length === 0;
    },
    alertTitle() {
      return this.canDelete ? 'Bekræft sletning - Dette kan ikke laves om eller gendannes og er sidste advarsel' : 'Kan ikke slette gruppe';
    },
  },
  methods: {
    buildHierarchy(group) {
      if (!group) return null;
      return {
        name: group.name,
        type: 'group',
        children: (group.groups || []).map((subgroup) => this.buildHierarchy(subgroup)).filter(Boolean),
      };
    },
    getContainedWorkspaces(group, workspaces = [], parentName = '') {
      if (!group) return workspaces;

      const groupName = parentName ? `${parentName} / ${group.name}` : group.name;

      const filterAndMapWorkspaces = (ws) => ws
          .map((workspace) => ({
            ...workspace,
            groupName,
          }));

      if (group.workspaces && Array.isArray(group.workspaces)) {
        const filteredWorkspaces = filterAndMapWorkspaces(group.workspaces);
        workspaces.push(...filteredWorkspaces);
      }

      if (group.groups && Array.isArray(group.groups)) {
        group.groups.forEach((subgroup) => {
          this.getContainedWorkspaces(subgroup, workspaces, groupName);
        });
      }

      return workspaces;
    },
    confirmDelete() {
      if (this.canDelete) {
        this.$emit('delete', this.selectedGroupId);
        this.selectedGroupId = '';
        this.model = false;
      }
    },
  },
};
</script>
