<template>
  <base-modal
      v-model="model"
      :close-on-click-outside="true"
      title="Omdøb gruppe"
      :can-overflow="true"
  >
    <base-select
        v-model="selectedGroupIdComputed"
        :options="filteredGroupOptions"
        label="Vælg gruppe"
        placeholder="Vælg en gruppe at omdøbe"
        class="wide-select"
    />
    <base-input
        v-if="selectedGroupId"
        v-model="newName"
        type="text"
        class="mt-4"
        label="Nyt navn"
        :required="true"
    />
    <div slot="footer" class="flex justify-end">
      <base-button
          color="indigo"
          :disabled="!selectedGroupId || !newName"
          @click="renameGroup"
      >
        Omdøb
      </base-button>
    </div>
  </base-modal>
</template>

<script>
export default {
  name: 'WorkspaceRenameGroupModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    groupOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedGroupId: '',
      newName: '',
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    selectedGroupIdComputed: {
      get() {
        return this.selectedGroupId || '';
      },
      set(value) {
        this.selectedGroupId = value;
      },
    },
    filteredGroupOptions() {
      return this.groupOptions.filter((option) => option.value);
    },
  },
  methods: {
    renameGroup() {
      if (this.selectedGroupId && this.newName) {
        this.$emit('rename', {
          id: this.selectedGroupId,
          newName: this.newName,
        });
        this.resetForm();
      }
    },
    resetForm() {
      this.selectedGroupId = '';
      this.newName = '';
    },
  },
};
</script>

<style scoped>
.wide-select {
  width: 100%;
  max-width: 500px;
}
</style>
